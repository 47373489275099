




























































import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { apiBuilder } from '../../config'
import axios from 'axios'
import dayjs from 'dayjs'

const auth = namespace('auth')

@Component
export default class OrderHistory extends Vue {
	@auth.State
	private token!: string
	@auth.State
	private username!: string

	private loading = true

	private expanded = []

	private tableItem = {
		header: [
			{
				text: 'Mã Tài Khoản',
				sortable: false,
				value: 'accountid',
				align: 'center',
			},
			{
				text: 'Tên Sản Phẩm',
				value: 'name',
				sortable: false,
				align: 'center',
			},
			{
				text: 'Trạng Thái',
				value: 'expired',
				sortable: false,
				align: 'center',
			},
			{
				text: 'Gia Hạn Tự Động',
				value: 'autorenew',
				sortable: false,
				align: 'center',
			},
			{
				text: '',
				value: 'data-table-expand',
			},
		],
		items: [],
	}

	private autoRenew = []

	//METHOD

	private getOrderHistory(): void {
		this.loading = true
		const apiUrl = apiBuilder('product/listpurchasedproduct.php')

		const param = {
			params: {
				username: this.username,
				token: this.token,
			},
		}

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error == 781) {
					sessionStorage.setItem('redirectPath', window.location.pathname)
					return this.$router.replace({ name: 'Login' })
				} else if (error != 780) {
					// console.log(error)

					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Please Reload !',
					})
				} else {
					const data = res.data.data

					// eslint-disable-next-line
					this.tableItem.items = data.map((x: any) => {
						return {
							accountid: x.accountid,
							name: x.product_name,
							expired: dayjs().unix() > Number(x.expired_at) ? true : false,
							autorenew: x.autoextend == 0 ? false : true,
							email: x.email,
							password: x.password,
							expiredAt: dayjs.unix(x.expired_at).format('DD/MM/YYYY HH:mm:ss'),
							slot: x.slot,
						}
					})

					this.loading = false
				}
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	private updateAutoReNew(accountid: string, status: boolean): void {
		const apiUrl = apiBuilder('product/editautoextend.php')

		const param = {
			params: {
				token: this.token,
				username: this.username,
				accountid: accountid,
				status: !status ? '1' : '0',
			},
		}

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error

				if (error == 711) {
					sessionStorage.setItem('redirectPath', window.location.pathname)
					return this.$router.replace({ name: 'Login' })
				} else if (error == 712) {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Bạn Không Sở Hữu Sản Phẩm Này !',
					})
				} else if (error == 713) {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Account ID Phải Là Số !',
					})
				} else if (error != 710) {
					this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error ! Please Reload',
					})
				} else {
					this.$swal({
						icon: 'success',
						title: 'Thành Công !',
						text: 'Đổi Trạng Thái Thành Công',
					})

					this.getOrderHistory()
				}
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})
	}

	private extendAccount(accountid: number): void {
		this.$swal({
			icon: 'question',
			title: 'Bạn Chắc Chưa ?',
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: 'Huỷ Bỏ',
			confirmButtonText: 'Chắc Rồi !',
			reverseButtons: true,
			focusConfirm: true,
		})
			.then((x) => {
				// console.log(x)

				if (x.isConfirmed) {
					const apiUrl = apiBuilder('product/extendaccount.php')

					const param = {
						params: {
							token: this.token,
							username: this.username,
							accountid: accountid,
						},
					}

					axios.post(apiUrl, null, param).then((res) => {
						const error = res.data.error

						if (error == 792) {
							sessionStorage.setItem('redirectPath', window.location.pathname)
							return this.$router.replace({ name: 'Login' })
						} else if (error == 793) {
							this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Bạn Không Sở Hữu Sản Phẩm Này !',
							})
						} else if (error == 794) {
							this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Account ID Phải Là Số !',
							})
						} else if (error == 795) {
							this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Không Đủ Tiền !',
							})
						} else if (error != 791) {
							this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Server Error ! Please Reload',
							})
						} else {
							this.$swal({
								icon: 'success',
								title: 'Thành Công !',
								text: 'Gia Hạn Thành Công !',
							})

							this.getOrderHistory()
						}
					})
				}
			})
			.catch((e) => {
				{
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: e,
					})
				}
			})
	}

	created() {
		this.getOrderHistory()
	}
}
